<template>
  <div
    class="container"
    v-if="tradeType==1"
  >
    <div class="row sb_center">
      <!-- <img
        width="180"
        src="@/assets/imgs/app_icon_zhuanti_logo.png"
        alt=""
      /> -->

      <div style="width:180px" />
    </div>
    <div class="iframe-div">
      <h1 class="title mb20">{{name}}</h1>
      <p
        class="first-font"
        v-for="item in content"
        :key="item"
      >{{item}}</p>
    </div>
  </div>
  <div
    v-else
    class="content"
  >
    <div class="hearder-div">
      <img
        src="https://resource.fzhitong.cn/agent_promise_bg.png"
        alt=""
        style="height:170px;width:100%"
      >
      <div class="poTitle">服务承诺：真实房源，假一赔百元</div>
    </div>
    <div class="mt40 ">
      <div
        v-for="item,index in servicePromise"
        :key="index"
      >
        <h1 class="title2 mb20 mt40">{{item.title}}</h1>
        <p
          class="first-font"
          v-for="item in item.content"
          :key="item"
        >{{item}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tradeType: 1,
      image: "https://resource.fzhitong.cn/agent_shop_money.png",
      title: "四、房源假一赔100",
      name: "房源假一赔100",
      content: [
        "一、承诺内容：经纪人在各渠道发布的房源信息真实存在、真实在售、真实在租、真实价格、真实图片。",
        "1.真实存在是指房源真实存在，不是虚构或虚拟房源真实在售是指房源的在租在售状态反映业主真实意愿；真实价格是指在各渠道发布房源价格以业主委托报价为基准；真实图片是指房源图片与真实房屋一对一匹配。",
        "2.若房屋状态或价格信息发生变化（包括业主变更出售 / 出租意向、业主调整价格或房屋成交等），与经经人发布房源信息时间间隔在24小时以上的。",
        "二、保障措施：",
        "1.如发现不符合上述承诺内容的房源，丙方应当向举报该条房源信息者支付人民币大写壹佰元整。",
        "2.每套房源第一次举报后，进入72小时核实期，核实期内该房源的其他客户举报不予受理。",
        "3.为了保证公平公正，如发现举报人提供的房源信息系通过非法或不当手段获得，一经证实将永久取消参加“假一赔百元”的赔付资格并保留追究其责任的权利。",
      ],
      servicePromise: [
        {
          image: "https://resource.fzhitong.cn/agent_shop_tax.png",
          title: "一、不吃税费差，“吃一罚十”",
          name: "不吃税费差，“吃一罚十”",
          content: [
            "1、承诺内容：在签订《房屋买卖经纪合同》后，由工作人员协助甲乙双方到税务部门缴纳税费，缴税标准由税务部门统一核定的缴税标准为准。",
            "2、保障措施：工作人员承诺决不吃税费差。若《房屋买卖经纪合同》签订以后，任何一方发现工作人员有此行为，公司将以差价的十倍进行赔付。",
          ],
        },
        {
          image: "https://resource.fzhitong.cn/agent_shop_price.png",
          title: "二、不吃房价差，“吃一罚十”",
          name: "不吃房价差，“吃一罚十”",
          content: [
            "1、承诺内容：我们以甲乙双方真实意愿的真实房屋价款如实拟定《房屋买卖经纪合同》，我们承诺每一笔交易都公开透明。",
            "2、保障措施：如任何一方发现工作人员赚取房屋差价，公司十倍退还买方差价部分房款。",
          ],
        },
        {
          image: "https://resource.fzhitong.cn/agent_shop_sign.png",
          title: "三、签约风险告知",
          name: "签约风险告知",
          content: [
            "1、承诺内容：在《房屋买卖经纪合同》签约前，工作人员需书面告知甲乙双方交易流程中存在的各风险点。",
            "2、保障措施：因未履行告知义务而产生的损失, 由丙方负责按实际产生的损失补偿赔付，但补偿上限为：人民币2000元。",
          ],
        },
        {
          image: "https://resource.fzhitong.cn/agent_shop_money.png",
          title: "四、房源假一赔100",
          name: "房源假一赔100",
          content: [
            "1、承诺内容：经纪人在各渠道发布的房源信息真实存在、真实在售、真实价格、真实图片。",
            "1.1、真实存在是指房源真实存在，不是虚构或虚拟房源真实在售是指房源的在租在售状态反映业主真实意愿；真实价格是指在各渠道发布房源价格以业主委托报价为基准；真实图片是指房源图片与真实房屋一对一匹配。",
            "1.2、若房屋状态或价格信息发生变化（包括业主变更出售 / 出租意向、业主调整价格或房屋成交等），与经经人发布房源信息时间间隔在24小时以上的。",
            "2、保障措施：",
            "2.1、如发现不符合上述承诺内容的房源，丙方应当向举报该条房源信息者支付人民币大写壹佰元整。",
            "2.2、每套房源第一次举报后，进入72小时核实期，核实期内该房源的其他客户举报不予受理。",
            "2.3、为了保证公平公正，如发现举报人提供的房源信息系通过非法或不当手段获得，一经证实将永久取消参加“假一赔百元”的赔付资格并保留追究其责任的权利。",
          ],
        },
        {
          image: "https://resource.fzhitong.cn/agent_shop_car.png",
          title: "五、过户枉跑，500元/次",
          name: "过户枉跑，500元/次",
          content: [
            "1、承诺内容：在签订《房屋买卖经纪合同》后，工作人员在办理该房屋售后服务中积极履行通知义务，包括但不限于短信、微信、电话、书面通知等方式。",
            "1.1、因工作人员通知不到位或工作失误导致甲方、乙方枉跑。",
            "2、保障措施：",
            "2.1、每枉跑一次，由丙方补偿枉跑方人民币500元 / 次",
            "2.2、因工作人员自身原因以外的其他因素，如因房管局、银行等相关单位停电、开会、设备系统维护、单位工作人员不在岗、所需资料发生变更等无法办理的情况除外。",
          ],
        },
        {
          image: "https://resource.fzhitong.cn/agent_shop_bank.png",
          title: "六、银行放贷，超时赔息",
          name: "银行放贷，超时赔息",
          content: [
            "1、承诺内容：经丙方居间成交的二手房，乙方按揭贷款的，甲、乙双方办理完不动产过户次日起算90个工作日之内；乙方公积金贷款的，从甲、乙双方提交完整的公积金贷款申请资料且公积金办理机构受理次日起算80个工作日之内收到买方贷款部份房款。",
            "2、保障措施：",
            "2.1、甲方应收到但实际未收到乙方贷款部份房款，由丙方以贷款银行同期的存款利率向甲方进行赔付，直至贷款的银行将贷款具体金额放款至甲方指定收款银行帐户当日截止。",
            "2.2、因甲、乙双方自身原因延误办理上述手续的，延误时间不包含在上述约定的工作日之内并自动顺延。",
          ],
        },
        {
          image: "https://resource.fzhitong.cn/agent_shop_property.png",
          title: "七、物业欠费，先行垫付",
          name: "物业欠费，先行垫付",
          content: [
            "1、承诺内容：经丙方居间成交的二手房，丙方应协助甲、乙双方向相关机关、部门核实物业、水、电、燃气的缴存费用情况。",
            "2、保障措施：若丙方未尽核实义务，在物业交割完毕后，乙方若发现甲方物业、水、电、燃气等费用有拖欠导致被限制入住，在向甲方催缴后拒不结算的，由丙方先行垫付。丙方垫付后，有权从甲方缴纳的交房保障金中予以扣除，且乙方应当积极配合丙方追偿。",
          ],
        },
        {
          image: "https://resource.fzhitong.cn/agent_shop_agency.png",
          title: "八、资金代管，保障安全",
          name: "资金代管，保障安全",
          content: [
            "1、承诺内容：在签订《房屋买卖经纪合同》后，由工作人员协助甲乙双方到中国建设银行乐山高新区支行办理交易资金监管事宜，无论付款方式系一次性付款或按揭付款，在办理过户手续之前，由乙方先行将购房款打入建行开设的交易资金监管帐户，待甲乙双方符合资金监管协议划转约定条件的当日内再将该笔房款直接划转至甲方指定银行帐户内。",
            "2、保障措施：丙方承诺按照我公司规定的流程进行100 % 资金监管，我公司保证该笔资金的安全，若监管资金出现风险，我公司承诺先行垫付。",
          ],
        },
        {
          image: "https://resource.fzhitong.cn/agent_shop_handle.png",
          title: "九、凶宅隐满，全额回购",
          name: "凶宅隐满，全额回购",
          content: [
            "1、承诺内容：经丙方居间成交的二手房，由丙方对房屋进行筛查，如筛查结果为该房屋在签署《房屋买卖经纪合同》前房屋本体结构内曾发生过自杀、他杀事件，且丙方未尽到信息披露义务的，丙方将对购房人进行补偿，最高至原价回购。",
            "1.1：“凶宅”是指：房屋本体结构内曾发生自杀、他杀事件，并在公安机关有正式备案记录的房屋，该房屋应符合以下特征：",
            "（1）房屋本体结构内发生的，即人员的死亡，是在该房屋产权证书所载明的面积范围内发生的，不包括电梯、楼梯间以及车位等配套建筑设施；",
            "（2）死亡原因为自杀、他杀事件。",
            "1.2：“原价”是指本次交易的客户购买交易房屋的价格，即《房屋买卖经纪合同》中所载明的房屋成交价，不包括税费、利息等其他花费。",
            "2、保障措施：",
            "2.1、若客户在签约后至过户前申请补偿的，丙方将全额退还所收居间服务费。",
            "2.2、若交易房已完成过户手续的，如出售人刻意隐满且经协商拒不退房或给付补偿，乙方也不愿继续持有该房产的，丙方全额退还已收取的居间服务费，并按本次交易的原价进行回购。",
          ],
        },
        {
          image: "https://resource.fzhitong.cn/agent_shop_pay.png",
          title: "十、合同签署前房屋查封，先行垫付",
          name: "合同签署前房屋查封，先行垫付",
          content: [
            "1、承诺内容：经丙方居间成交的二手房，丙方在签署《房屋买卖经纪合同》前对交易房屋信息进行核验，确认是否被国家机关查封，并如实告知乙方。",
            "2、保障措施：",
            "2.1、若丙方未对交易房屋是否存在查封信息进行核实和披露，且乙方已付购房款暂时不能追回的，丙方应当先行垫付乙方所有已实际支付且无法追回的购房款。乙方收取垫付款后，应当积极配合丙方追缴款项。乙方配合丙方追偿过程中产生的诉讼费和律师费由丙方先行垫付，待追偿后予以结算。",
            "2.2、若甲方已明确告知房屋有查封，乙方已知情且达成一致协议的，丙方将不予垫付。",
          ],
        },
      ],
    };
  },
  created() {
    this.tradeType = this.$route.query.tradeType || 1;
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 960px;
  margin: 28px auto;
  .title {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
  }
  .iframe-div {
    padding: 30px;
    position: absolute;
    top: 100px;
    bottom: 100px;
    left: 50%;
    min-height: 80vh;
    margin-left: -400px;
    width: 800px;
    overflow: auto;
    color: #101d37;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #e5e5e6;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  }
}
.first-font {
  text-indent: 2em;
  line-height: 3em;
}
p {
  margin-left: 15px;
  letter-spacing: 5px;
}
h1 {
  font-weight: 500;
}
.hearder-div {
  position: relative;
  left: 0;
  top: 0;
  .poTitle {
    position: absolute;
    top: 75px;
    left: 0;
    width: 100%;
    font-size: 24px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
  }
}
.title2 {
  font-size: 24px;
}
</style>